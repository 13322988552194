@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}


body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #FFFFFF;
}

.content-above-image {
  text-align: center;
  padding: 20px 30px;
  margin-top: 200px;
  position: absolute;
  color: white;
  opacity: 0.5;
}

.content-above-image.animate {
  animation: fadeInUp 1s ease forwards; /* Apply animation */
}



.image-below-navbar {
  width: 100%;
  display:flex;
  justify-content: center;
}

.image-below-navbar img {
  max-width: 100%;
  height: 100%;
}

.contact-icon-container {
  position: fixed;
  bottom: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  z-index: 999; /* Ensure the icon appears on top of other elements */
}

.contact-link img {
  width: 50px; /* Adjust the size of the icon as needed */
  height: 50px; /* Adjust the size of the icon as needed */
  border-radius: 50%; /* Make the container circular */
  border: 2px solid #fff; /* Add a border to the icon */
  background-color: #574c4c; /* Set the background color of the icon */
  padding: 10px; /* Adjust as needed */
}

.contact-link img:hover {
  background-color: #7e7171; /* Change background color on hover */
}

.contact-icons {
  display: flex;
}

.whatsapp-icon,
.telephone-icon {
  margin-right: 10px; /* Adjust spacing between icons as needed */
}

.header {
  text-align: center;
  padding: 50px 0;
  background-color: #ffffff;
  justify-content: center;
}

.header h1 {
  font-size: 30px; /* Set font size for the main heading */
  color: #000000; /* Set text color */
  margin-bottom: 20px; /* Add some bottom margin */
}

.header h6 {
  font-size: 18px; /* Set font size for the subheading */
  color: #000000f6; /* Set text color */
  margin-bottom: 20px; /* Add some bottom margin */
}

.header p {
  font-size: 16px; /* Set font size for the paragraph */
  color: #000000; /* Set text color */
  line-height: 1.6; /* Set line height */
}

.paragraph {
  font-size: 16px; /* Set font size for the paragraph */
  color: #000000; /* Set text color */
  line-height: 1.6; /* Set line height */
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

/* .body {
  background: #ADD8E6;
 
} */

.footer {
  position: relative;
  width: 100%;
  background: #3586ff;
  min-height: 100px;
  padding: 20px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
}

.social-icon,
.menu {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  flex-wrap: wrap;
}

.social-icon__item,
.menu__item {
  list-style: none;
}

.social-icon__link {
  font-size: 2rem;
  color: #fff;
  margin: 0 10px;
  display: inline-block;
  transition: 0.1s;
}
.social-icon__link:hover {
  transform: translateY(-10px);
}

.menu__link {
  font-size: 1.2rem;
  color: #fff;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
  text-decoration: none;
  opacity: 0.75;
  font-weight: 300;
}

.menu__link:hover {
  opacity: 1;
}

.footer p {
  color: #ffffff;
  margin: 15px 0 10px 0;
  font-size: 1rem;
  font-weight: 300;
}

.wave {
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  height: 100px;
  background: url("https://i.ibb.co/wQZVxxk/wave.png");
  background-size: 1000px 100px;
}

.wave#wave1 {
  z-index: 1000;
  opacity: 1;
  bottom: 0;
  animation: animateWaves 4s linear infinite;
}

.wave#wave2 {
  z-index: 999;
  opacity: 0.5;
  bottom: 10px;
  animation: animate 4s linear infinite !important;
}

.wave#wave3 {
  z-index: 1000;
  opacity: 0.2;
  bottom: 15px;
  animation: animateWaves 3s linear infinite;
}

.wave#wave4 {
  z-index: 999;
  opacity: 0.7;
  bottom: 20px;
  animation: animate 3s linear infinite;
}

@keyframes animateWaves {
  0% {
    background-position-x: 1000px;
  }
  100% {
    background-positon-x: 0px;
  }
}

@keyframes animate {
  0% {
    background-position-x: -1000px;
  }
  100% {
    background-positon-x: 0px;
  }
}


.store-icon {
  width: 150px;
  height: auto;
  margin-right: 1rem;
}


.store-icon:hover {
  opacity: 0.8;
}

.about-us-container {
    padding: 20px;
    text-align: center;
  }
  
  h1 {
    font-size: 2.8rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .content-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .left-section {
    flex: 1;
    text-align: left;
  }
  
  .right-section {
    flex: 1;
  }
  
  /* img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  } */
  
  /* .contact-info {
    margin-top: 50px;
    text-align: left;
  } */
  
  /* h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 1.4rem;
    margin-bottom: 5px;
  } */



  /* banner.css */

.advantages-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 40px;
}

.advantage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.advantages {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s, transform 0.5s;
}

.advantages.animate {
  opacity: 1;
  transform: translateY(0);
}

.advantage img {
  width: 100px;
  height: auto;
}

.advantage p {
  color: #000000;
  margin-top: 10px;
  font-size: 20px;
  text-align: center;
}

/* Define keyframes for the animation */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply animation to the h1 element */
.header h1 {
  animation: fadeInUp 0.5s ease forwards;
}

.paragraph {
  color: #4b4747;
  margin-top: 5px;
  font-size: 13px;
  text-align: center;
}

.paragraphs {
  color: #000000;
  margin-top: 10px;
  font-size: 30px;
  text-align: center;
}

/* Add this CSS to your existing stylesheet or create a new one */

/* Style for the "READ MORE" button */
.read-more-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff; /* Blue color, you can change it to suit your design */
  color: #fff; /* White text color */
  text-decoration: none; /* Remove underline */
  border-radius: 5px; /* Rounded corners */
  margin-top: 10px; /* Add some top margin */
  transition: background-color 0.3s; /* Smooth transition effect */
}

/* Hover effect for the "READ MORE" button */
.read-more-button:hover {
  background-color: #0056b3; /* Darker shade of blue */
}

/* Adjustments for the paragraphs */
.paragraphs {
  margin-top: 10px; /* Add some top margin */
  color: #333; /* Text color */
}


/* navbar.css */

/* Other styles... */

.login-button {
  margin-left: 70px; /* Push the button to the right */
}

.login-link {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  transition: background-color 0.3s;
}

.login-link:hover {
  background-color: #0056b3;
}



/* banner3 css */

.devices-container {
  margin: 20px;
}

.device-list {
  list-style-type: none;
  padding: 0;
}

.device-item {
  background-color: #f4f4f4;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.device-item:hover {
  background-color: #e0e0e0;
}


.main-section {
  padding: 50px 0;
}

.containers {
  max-width: 1200px;
  margin: 0 auto;
}

.approved-by-inside {
  display: flex;
  align-items: center;
}

.columns {
  display: flex;
}

.column {
  flex: 1;
}

.images {
  display: flex;
  justify-content: center;
  align-items: center;
}

.images img {
  width: 150px; /* Adjust the width as needed */
  height: auto;
  margin-right: 20px; /* Adjust spacing between images */
}

.approved-content {
  padding: 0 20px; /* Adjust padding as needed */
}

h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
  line-height: 1.6;
}

p span {
  font-weight: bold;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .columns {
    flex-direction: column;
  }

  .images {
    margin-bottom: 20px;
  }

  .images img {
    margin-right: 20px;
    margin-bottom: 10px;
  }
}

/* banner4 css */
.column {
  margin-top: 20px;
}

header {
  margin-bottom: 20px;
}

.headingone {
  font-size: 24px;
  margin: 10px 10px;
}

.section-content {
  margin-bottom: 20px;
}

.headingparagraph {
  font-size: 18px;
  line-height: 1.6;
  margin: 10px 10px;
}

.achivements-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10px 10px;
}

.achivements-list div {
  flex: 0 0 calc(50% - 20px); /* Adjusted for responsive layout */
  margin-bottom: 20px;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 5px;
  text-align: center;
  box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
}

.icon {
  font-size: 36px;
}

h2 {
  font-size: 18px;
  margin: 10px 0;
}

p {
  font-size: 14px;
  margin: 0;
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .achivements-list div {
    flex: 0 0 calc(33.333% - 20px); /* Adjusted for responsive layout */
  }
}

@media (min-width: 1024px) {
  .column {
    flex-basis: calc(50% - 20px); /* Adjusted for responsive layout */
  }
}


/* banner5 */
/* 
.banner5 {
  background-color: #f9f9f9;
  padding: 40px 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.banner-title {
  text-align: center;
  font-size: 32px;
  margin-bottom: 40px;
}

.columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.column {
  flex-basis: 100%;
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  .column {
    flex-basis: calc(50% - 20px);
  }
}

@media (min-width: 768px) {
  .column {
    flex-basis: calc(33.333% - 20px);
  }
}

.card-started {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.card-title-number {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.card-title-number .icon {
  font-size: 36px;
}

.card-content-get h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.card-content-get p {
  font-size: 16px;
  line-height: 1.6;
  color: #666;
} */


.banner5 {
  background-color: #f9f9f9;
  padding: 40px 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.banner-title {
  text-align: center;
  font-size: 32px;
  margin-bottom: 40px;
}

.columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.column {
  flex-basis: calc(25% - 20px);
  margin-bottom: 20px;
}

.card-started {
  background-color: #f4f4f4;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.card-title-number {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.card-title-number .icon {
  font-size: 36px;
}

.card-content-get h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.card-content-get p {
  font-size: 16px;
  line-height: 1.6;
  color: #666;
}


